import { RouterStore } from 'prosumer-app/libs/eyes-core';
import { BaseComponent } from 'prosumer-app/libs/eyes-shared';
import { CaseFacadeService } from 'prosumer-case/state';
import { ProjectFacadeService } from 'prosumer-project/state';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'prosumer-case-create-page',
  templateUrl: './case-create-page.component.html',
  styleUrls: ['./case-create-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseCreatePageComponent extends BaseComponent implements OnInit {
  parentProjectId: string;

  mode$ = this._route.data.pipe(
    take(1),
    filter((data) => data !== undefined),
    map((data) => data.mode),
    this.takeUntilShare(),
  );

  routeParams$ = this._route.params.pipe(
    take(1),
    filter((params) => !!params),
    this.takeUntilShare(),
  );

  projectIdParam$ = this.routeParams$.pipe(
    map((params) => params.projectId),
    tap((projectId) => this._projectFacade.selectId(projectId)),
    this.takeUntilShare(),
  );

  selectedProject$ = this._projectFacade.selectedData$.pipe(
    this.takeUntilShare(),
  );

  getProjectData$ = this.selectedProject$.pipe(
    take(1),
    filter((project) => !!!project),
    withLatestFrom(this.projectIdParam$),
    tap(([project, projectId]) => this._projectFacade.get(projectId)),
    this.takeUntilShare(),
  );

  creating$ = this._caseFacade.creating$.pipe(this.takeUntilShare());

  constructor(
    private _route: ActivatedRoute,
    private _projectFacade: ProjectFacadeService,
    private _caseFacade: CaseFacadeService,
    private _routerStore: RouterStore,
  ) {
    super();
  }

  ngOnInit() {
    this.getProjectData$.subscribe();
    this.projectIdParam$.subscribe((projID) => (this.parentProjectId = projID));
  }

  onCreate(_case: any): void {
    // Map form group to Case interface
    this._caseFacade.create({
      ..._case,
      economics: {
        wacc: _case.wacc,
      },
      projectId: this.parentProjectId,
    });
  }

  onCancel(): void {
    this._routerStore.back();
  }
}
