import { EntityState } from '@datorama/akita';

export interface TDBDataNewState extends EntityState<TDBDataNewSummary> {
  readonly types?: string[];
  readonly filtersOfTypeSlider?: string[];
  readonly filters?: Record<string, string[] | number[]>;
  readonly activeFilters?: Record<string, string | number>;
}

export interface FilterConfigNewResponse {
  readonly filters: Record<string, string[] | number[]>;
  readonly filtersOfTypeSlider: string[];
}

export interface FilterTypeConfigNewResponse {
  readonly types: string[];
}

export interface TDBDataNewSummary extends Record<string, unknown> {}

export interface TDBDataNewProfile {
  data: Record<string, string | number | boolean>[];
  type: string;
}

export enum TDBDataNewGranularity {
  hourly = 'hourly',
  yearly = 'yearly',
}
