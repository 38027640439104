import { UserInfoResolver } from 'prosumer-app/core/guards/user-info';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  CaseCreatePageComponent,
  CaseDetailsPageComponent,
} from './containers';
import { CaseResolverService } from './services/case-resolver.service';

const routes: Routes = [
  {
    path: 'create',
    component: CaseCreatePageComponent,
    data: {
      breadcrumb: 'Create Case',
      mode: 'create',
      reference: false,
      trim: false,
    },
    resolve: {
      user: UserInfoResolver,
    },
  },
  {
    path: ':caseId',
    component: CaseDetailsPageComponent,
    data: {
      breadcrumb: 'Case',
      referenceKey: 'caseId',
      reference: true,
      trim: false,
    },
    resolve: {
      user: UserInfoResolver,
      case: CaseResolverService,
    },
  },
  {
    path: ':caseId/scenarios',
    loadChildren: () =>
      import('../+scenario/scenario.module').then((m) => m.ScenarioModule),
    data: {
      breadcrumb: 'Case',
      referenceKey: 'caseId',
      reference: true,
      trim: true,
      trimPath: '/scenarios',
    },
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CaseRoutingModule {}
