import {
  EXECUTION_STATUS,
  EXECUTION_STATUS_SUCCESS,
} from 'prosumer-app/app.references';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { Utils } from 'prosumer-core/utils';
import { Scenario } from 'prosumer-scenario/models';
import { ScenarioFacadeService } from 'prosumer-scenario/state';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, mergeMap, startWith } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { CaseDetailsPageExt } from './case-details-page.ext';

export type StatusQuery = '' | 'draft' | 'succeeded';

@Injectable()
export class CaseDetailsPageService {
  private readonly searchQuery = new BehaviorSubject<string>('');
  private readonly statusGroupQuery = new BehaviorSubject<StatusQuery>('');

  constructor(
    private readonly keeper: ActiveKeeperService,
    private readonly scenarios: ScenarioFacadeService,
  ) {}

  setEditPermission(hasEditPermission: boolean): void {
    this.keeper.setActive({ hasEditPermission });
  }

  setSearchQuery(query: string) {
    this.searchQuery.next(query);
  }

  selectScenarios(): Observable<Scenario[]> {
    return this.combineScenariosNameAndStatusQueries().pipe(
      mergeMap(() => this.selectScenariosFromStore()),
      map((scenarios) => this.onlyMatchingTheNameQuery(scenarios)),
      map((scenarios) => this.handleStatusGroupQuery(scenarios)),
    );
  }

  selectScenariosAndVariations(): Observable<unknown[]> {
    return this.scenarios
      .getScenariosAndVariations({
        projectId: this.keeper.getActiveEntities().project,
        caseId: this.keeper.getActiveEntities().case,
        status: EXECUTION_STATUS_SUCCESS,
      })
      .pipe(map(this.getDatalist));
  }

  setStatusGroupQuery(query: StatusQuery) {
    this.statusGroupQuery.next(query);
  }

  dispatchGetScenariosOfActive(): void {
    this.scenarios.getCaseDetailScenarioList(
      this.keeper.getActiveEntities().case,
      this.buildGetScenariosParams(),
    );
  }

  handleStatusGroupQuery(scenarios: Scenario[]): Scenario[] {
    return this.shouldFilterByStatusGroup()
      ? CaseDetailsPageExt.matchScenariosWithStatus(
          scenarios,
          this.statusQueryBag[this.getStatusQuery()],
        )
      : scenarios;
  }

  onlyMatchingTheNameQuery(scenarios: Scenario[]): Scenario[] {
    return CaseDetailsPageExt.matchScenariosWithName(
      scenarios,
      this.searchQuery.getValue(),
    );
  }

  private buildGetScenariosParams(): { projectId: string } {
    return {
      projectId: this.keeper.getActiveEntities().project,
    };
  }

  private selectScenariosFromStore(): Observable<Scenario[]> {
    return this.keeper.selectScenarioParents().pipe(
      mergeMap((parents) =>
        this.scenarios.filterScenarios$(parents.project, parents.case),
      ),
      startWith([]),
    );
  }

  private combineScenariosNameAndStatusQueries(): Observable<unknown> {
    return combineLatest([
      this.selectScenariosFromStore(),
      this.searchQuery,
      this.statusGroupQuery,
    ]);
  }

  private get statusQueryBag(): Record<StatusQuery, string[]> {
    return {
      '': [],
      draft: [EXECUTION_STATUS.DRAFT],
      succeeded: EXECUTION_STATUS_SUCCESS,
    };
  }

  private shouldFilterByStatusGroup(): boolean {
    return this.getStatusQuery() !== '';
  }

  private getStatusQuery(): StatusQuery {
    return this.statusGroupQuery.getValue();
  }

  private getDatalist(response: unknown): unknown[] {
    const payload = Utils.resolveToEmptyObject(response['payload']);
    return Utils.resolveToEmptyArray(payload['datalist']);
  }
}
