import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OpCostsListerDirective } from './op-costs-lister.directive';

@NgModule({
  declarations: [OpCostsListerDirective],
  imports: [CommonModule],
  exports: [OpCostsListerDirective],
})
export class OpCostsListerModule {}
