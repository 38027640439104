import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OpCostsGetterDirective } from './op-costs-getter.directive';

@NgModule({
  declarations: [OpCostsGetterDirective],
  imports: [CommonModule],
  exports: [OpCostsGetterDirective],
})
export class OpCostsGetterModule {}
