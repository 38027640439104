import { Coerce } from 'prosumer-app/core/utils/coercion.util';
import {
  ANY_VALUE,
  TDB_TECH_SLIDER_LABELS,
} from 'prosumer-shared/modules/tdb/components/tdb-technology-filters/tdb-technology-filters.token';
import { mapToFormSelect } from 'prosumer-shared/modules/tdb/mappers/tdb-generic/tdb-generic.mapper';
import { BehaviorSubject } from 'rxjs';

import { Options } from '@angular-slider/ngx-slider';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

type FiltersDataObj = Record<string, string[] | number[]>;

@Component({
  selector: 'prosumer-tdb-technology-secondary-filters',
  templateUrl: './tdb-technology-secondary-filters.component.html',
  styleUrls: ['./tdb-technology-secondary-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdbTechnologySecondaryFiltersComponent implements OnChanges {
  @Output() secondaryFormSubmit = new EventEmitter();
  @Input() filterSequence = [];
  @Input() isLoading = false;
  @Input() filtersData: FiltersDataObj;
  @Input() slidersData: FiltersDataObj;

  secondaryFiltersForm: UntypedFormGroup;
  filterDataOptionsMapped$ = new BehaviorSubject([]);
  sliderDataOptionsMapped$ = new BehaviorSubject([]);

  constructor(private readonly _formBuilder: UntypedFormBuilder) {}

  handleSubmitBtnClick(): void {
    this.secondaryFormSubmit.emit(this.secondaryFiltersForm.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isValidChange(changes)) {
      const filtersData = changes.filtersData.currentValue;
      const slidersData = changes.slidersData.currentValue;
      const filterSeq = this.getFilterSequence(filtersData);
      const sliderSeq = this.getSliderSequence(slidersData);
      this.updateFiltersOptions(filterSeq, filtersData);
      this.updateSlidersOptions(sliderSeq, slidersData);
      this.initSecondaryFilterForm(filterSeq, sliderSeq);
    }
  }

  getSliderOptions(key: string): Options {
    const keyValue = this.slidersData[key] as number[];
    const [floor, ceil] = this.getMinMax(keyValue);
    return {
      stepsArray: keyValue.map((step) => ({ value: step })),
      floor,
      ceil,
    };
  }

  private isValidChange(changes: SimpleChanges): boolean {
    const filtersData = Coerce.toObject(changes.filtersData);
    return [
      this.isNonEmptyObject(filtersData.currentValue),
      this.isLoading === false,
    ].every(Boolean);
  }
  private isNonEmptyObject(obj: Record<string, unknown>): boolean {
    return !!Object.keys(Coerce.toObject(obj)).length;
  }
  private isNonEmptyArray(arr: string[] | number[]): boolean {
    return !!Coerce.toArray<unknown>(arr).length;
  }

  // private parseInputFilterData(_filtersData): void {
  //   let formData = {};
  //   let filtersDataWithOptionsMapped = {};
  //   Object.keys(_filtersData).forEach((key) => {
  //     filtersDataWithOptionsMapped[key] = mapToFormSelect(_filtersData[key]);
  //     formData[key] = ANY_VALUE;
  //   });
  //   this.filterDataOptionsMapped$.next(filtersDataWithOptionsMapped);
  //   this.initSecondaryFilterForm(formData);
  // }

  private getFilterSequence(filtersData: FiltersDataObj): string[] {
    return Coerce.toArray(this.filterSequence).filter((key) =>
      this.isNonEmptyArray(filtersData[key]),
    );
  }
  private getSliderSequence(slidersData: FiltersDataObj): string[] {
    return Coerce.toArray(this.filterSequence).filter((key) =>
      this.isNonEmptyArray(slidersData[key]),
    );
  }
  private updateFiltersOptions(seqArr: string[], data: FiltersDataObj): void {
    const filters = seqArr
      .filter((key) => !!data[key])
      .map((key) => ({
        values: mapToFormSelect(data[key]),
        key,
      }));
    this.filterDataOptionsMapped$.next(filters);
  }
  private updateSlidersOptions(seqArr: string[], data: FiltersDataObj): void {
    const sliders = seqArr
      .filter((key) => !!data[key])
      .map((key) => {
        const label = TDB_TECH_SLIDER_LABELS[key];
        const [floor, ceil] = this.getMinMax(data[key] as number[]);
        return { values: data[key], key, label, floor, ceil };
      });
    this.sliderDataOptionsMapped$.next(sliders);
  }
  private initSecondaryFilterForm(
    filterSeq: string[],
    sliderSeq: string[],
  ): void {
    const formData = {
      ...this.getDefaultForm(filterSeq, ANY_VALUE),
      ...this.getDefaultForm(sliderSeq, [0, 0]),
    };
    this.secondaryFiltersForm = this._formBuilder.group(formData);
  }
  private getDefaultForm(
    sequencedKeys: string[],
    defaultValue: unknown,
  ): Record<string, string> {
    return sequencedKeys.reduce(
      (acc, seqKey) => ({ ...acc, [seqKey]: defaultValue }),
      {},
    );
  }
  private getMinMax(arr: number[]): [number, number] {
    if (arr.length > 0) {
      return [arr[0], arr[arr.length - 1]];
    }
    return [0, 0];
  }
}
