import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LetDirective, PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';

import { UnsavedEntityBannerComponent } from './unsaved-entity-banner.component';

@NgModule({
  declarations: [UnsavedEntityBannerComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    LetDirective,
    PushPipe,
    MatIconModule,
    MatButtonModule,
  ],
  exports: [UnsavedEntityBannerComponent],
})
export class UnsavedEntityBannerModule {}
