import { Utils } from 'prosumer-core/utils';
import { Scenario } from 'prosumer-scenario/models';

export class CaseDetailsPageExt {
  static matchScenariosWithName(
    scenarios: Scenario[],
    name: string,
  ): Scenario[] {
    return scenarios.filter((scenario) =>
      this.insensitiveIncludes(scenario.name, name),
    );
  }

  static matchScenariosWithStatus(
    scenarios: Scenario[],
    status: string[],
  ): Scenario[] {
    return scenarios.filter((scenario) => status.includes(scenario.run.status));
  }

  private static insensitiveIncludes(a: string, b: string): boolean {
    return Utils.resolveToEmptyString(a)
      .toLowerCase()
      .includes(Utils.resolveToEmptyString(b).toLowerCase());
  }
}
