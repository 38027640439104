import { Directive, OnInit } from '@angular/core';
import { filterNilValue } from '@datorama/akita';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { EquipmentQuery } from 'prosumer-app/stores/equipment';
import { OperationalCostStore } from 'prosumer-app/stores/op-costs';
import { mergeMap, take } from 'rxjs/operators';

@Directive({
  selector: '[prosumerOpCostsGetter]',
})
export class OpCostsGetterDirective implements OnInit {
  constructor(
    private readonly costs: OperationalCostStore,
    private readonly equipments: EquipmentQuery,
    private readonly notifs: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.equipments
      .selectActiveId()
      .pipe(
        filterNilValue(),
        mergeMap((id) => this.costs.getOperationalCosts(id)),
      )
      .pipe(take(1))
      .subscribe({
        error: () => this.notifs.showError(this.getErrorMsg()),
      });
  }

  private getErrorMsg(): string {
    return 'Failed to get the operational costs for this equipment';
  }
}
