import { SelectNewModule } from 'prosumer-app/shared/components';
import { TDBReferencePipeModule } from 'prosumer-app/shared/pipes/tdb-reference';
import { SharedModule } from 'prosumer-app/shared/shared.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { TdbEmissionFactorsComponent } from './tdb-emission-factors.component';

@NgModule({
  declarations: [TdbEmissionFactorsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    TDBReferencePipeModule,
    SelectNewModule,
    SharedModule,
  ],
  exports: [TdbEmissionFactorsComponent],
})
export class TdbEmissionFactorsModule {}
