import { DER_TYPES } from 'prosumer-app/app.references';

export const ANY_VALUE = 'Any';
export const CATEGORIES_W_OPTION_ANY = ['subcategory', 'subsubcategory'];
export const VALUES_TO_IGNORE = [ANY_VALUE, undefined, null, ''];
export const PRIMARY_FILTER_HEIRARACHY = [
  'type',
  'category',
  'subcategory',
  'subsubcategory',
];
export const PRIMARY_FILTERS = PRIMARY_FILTER_HEIRARACHY;

const CELL_W_S = '120px';
const CELL_W_M = '160px';
const CELL_W_L = '200px';
const CELL_W_XL = '250px';
const CELL_W_XXL = '600px';

export const GENERIC_COLUMN_DEF = {
  selection: {
    type: 'selection',
    flex: '50px',
  },
  region: {
    name: 'Region',
    flex: CELL_W_S,
  },
  subregion: {
    name: 'Subregion',
    flex: CELL_W_M,
  },
  country: {
    name: 'Country',
    flex: CELL_W_M,
  },
  dataSource: {
    name: 'Data Source',
    flex: CELL_W_L,
  },
  dataSourceType: {
    name: 'Data Source Type',
    flex: CELL_W_M,
  },
  validityYearStart: {
    name: 'Start Year',
    flex: CELL_W_S,
  },
  validityYearEnd: {
    name: 'End Year',
    flex: CELL_W_S,
  },
  maxPower: {
    name: 'Max Power',
    flex: CELL_W_S,
    sortable: true,
  },
  minPower: {
    name: 'Min Power',
    flex: CELL_W_S,
    sortable: true,
  },
  buildCost: {
    name: 'Build Cost [€/kW]',
    flex: CELL_W_L,
    sortable: true,
    type: 'chart',
  },
  capacityLoss: {
    name: 'Capacity Loss [kW/year]',
    flex: CELL_W_L,
    sortable: true,
    type: 'chart',
  },
  fOAndMCharge: {
    name: 'FO&M Charge',
    flex: CELL_W_M,
    sortable: true,
    type: 'chart',
  },
  technicalLife: {
    name: 'Technical Life [year]',
    flex: CELL_W_L,
    sortable: true,
    type: 'chart',
  },
  depreciationTime: {
    name: 'Depreciation Time [year]',
    flex: CELL_W_XL,
    sortable: true,
    type: 'chart',
  },
};

export const GENERIC_COLUMN_DEF_LAST = {
  comments: {
    name: 'Comments',
    flex: CELL_W_XXL,
  },
};

export const VRE_COLUMNS_DEF = {
  ...GENERIC_COLUMN_DEF,
  ...GENERIC_COLUMN_DEF_LAST,
};

export const STORAGE_COLUMNS_DEF = {
  ...GENERIC_COLUMN_DEF,
  inputEnergyVectorId: {
    name: 'Energy Vector',
    flex: CELL_W_M,
    sortable: true,
  },
  maxEnergy: {
    name: 'Max Energy',
    flex: CELL_W_M,
    sortable: true,
    type: 'chart',
  },
  minEnergy: {
    name: 'Min Energy',
    flex: CELL_W_S,
    sortable: true,
    type: 'chart',
  },
  buildCost2: {
    name: 'Build Cost [€/kWh]',
    flex: CELL_W_L,
    sortable: true,
    type: 'chart',
  },
  capacityLoss2: {
    name: 'Capacity Loss [kWh/year]',
    flex: CELL_W_XL,
    sortable: true,
    type: 'chart',
  },
  fOAndMChargeKwh: {
    name: 'FO&M Charge [€/kWh/year]',
    flex: CELL_W_M,
    sortable: true,
    type: 'chart',
  },
  efficiency: {
    name: 'Roundtrip Efficiency',
    flex: CELL_W_L,
    sortable: true,
    type: 'chart',
  },
  maxEp: {
    name: 'Max E/P',
    flex: CELL_W_S,
    sortable: true,
    type: 'chart',
  },
  minEp: {
    name: 'Min E/P',
    flex: CELL_W_S,
    sortable: true,
    type: 'chart',
  },
  maxDod: {
    name: 'Max DoD',
    flex: CELL_W_S,
    sortable: true,
    type: 'chart',
  },
  chargingDischargingRateFactor: {
    name: 'Charging/Discharging Rate',
    flex: CELL_W_XL,
    sortable: true,
    type: 'chart',
  },
  ageingFactor: {
    name: 'Ageing Factor',
    flex: CELL_W_M,
    sortable: true,
    type: 'chart',
  },
  dissipationRate: {
    name: 'Dissipation Rate',
    flex: CELL_W_L,
    sortable: true,
    type: 'chart',
  },
  ...GENERIC_COLUMN_DEF_LAST,
};

export const GENERATOR_COLUMNS_DEF = {
  ...GENERIC_COLUMN_DEF,
  inputEnergyVectorId: {
    name: 'Input Energy Vector',
    flex: CELL_W_L,
    sortable: true,
  },
  outputEnergyVector1: {
    name: 'Output Energy Vector',
    flex: CELL_W_L,
    sortable: true,
  },
  pmin: {
    name: 'Pmin',
    flex: CELL_W_S,
    sortable: true,
    type: 'chart',
  },
  efficiencyPmax: {
    name: 'Efficiency @Pmax',
    flex: CELL_W_S,
    sortable: true,
    type: 'chart',
  },
  efficiencyPmin: {
    name: 'Efficiency @Pmin',
    flex: CELL_W_S,
    sortable: true,
    type: 'chart',
  },
  minUptime: {
    name: 'Minimum uptime',
    flex: CELL_W_S,
    sortable: true,
    type: 'chart',
  },
  minDowntime: {
    name: 'Minimum downtime',
    flex: CELL_W_M,
    sortable: true,
    type: 'chart',
  },
  minRunningTime: {
    name: 'Minimum runtime',
    flex: CELL_W_M,
    sortable: true,
    type: 'chart',
  },
  startUpLoss: {
    name: 'Start-up loss',
    flex: CELL_W_M,
    sortable: true,
    type: 'chart',
  },
  ...GENERIC_COLUMN_DEF_LAST,
};

export const CONVERTER_COLUMNS_DEF = {
  ...GENERIC_COLUMN_DEF,
  inputEnergyVector1: {
    name: 'Input Energy Vector 1',
    flex: CELL_W_L,
    sortable: true,
  },
  outputEnergyVector1: {
    name: 'Output Energy Vector 1',
    flex: CELL_W_L,
    sortable: true,
  },
  efficiency1: {
    name: 'Input 1 to Output 1',
    flex: CELL_W_L,
    sortable: true,
    type: 'chart',
  },
  inputEnergyVector2: {
    name: 'Input Energy Vector 2',
    flex: CELL_W_L,
    sortable: true,
  },
  outputEnergyVector2: {
    name: 'Output Energy Vector 2',
    flex: CELL_W_L,
    sortable: true,
  },
  efficiency2: {
    name: 'Input 2 to Output 2',
    flex: CELL_W_L,
    sortable: true,
    type: 'chart',
  },
  ...GENERIC_COLUMN_DEF_LAST,
};

export const TDB_TECH_TYPES_COLUMN_DEF = {
  [DER_TYPES.vre]: VRE_COLUMNS_DEF,
  [DER_TYPES.storage]: STORAGE_COLUMNS_DEF,
  [DER_TYPES.generator]: GENERATOR_COLUMNS_DEF,
  [DER_TYPES.converter]: CONVERTER_COLUMNS_DEF,
};

export const GENERIC_TECH_FILTER_SEQUENCE = [
  'category',
  'subcategory',
  'subsubcategory',
  'region',
  'subregion',
  'country',
  'manufacturer',
];
export const TECH_FILTER_SEQUENCE = [
  ...GENERIC_TECH_FILTER_SEQUENCE,
  'dataSource',
  'dataSourceType',
  'subcontracted',
  'outputCapacityKw',
];
export const STORAGE_FILTER_SEQUENCE = [
  ...GENERIC_TECH_FILTER_SEQUENCE,
  'subcontracted',
  'dataSource',
  'dataSourceType',
  'storedEnergyVector',
  'outputCapacityKw',
  'outputCapacityKwh',
];

export const TDB_TECH_FILTER_SEQUENCE = {
  [DER_TYPES.vre]: TECH_FILTER_SEQUENCE,
  [DER_TYPES.storage]: STORAGE_FILTER_SEQUENCE,
  [DER_TYPES.generator]: TECH_FILTER_SEQUENCE,
  [DER_TYPES.converter]: TECH_FILTER_SEQUENCE,
};

export const TDB_TECH_SLIDER_LABELS = {
  outputCapacityKw: 'Output Capacity (kW)',
  outputCapacityKwh: 'Output Capacity (kWh)',
};
