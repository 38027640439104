import { SelectNewModule } from 'prosumer-app/shared/components/select-new/select-new.module';
import { SharedModule } from 'prosumer-app/shared/shared.module';
import { TdbTechnologySecondaryFiltersComponent } from 'prosumer-shared/modules/tdb/components/tdb-technology-secondary-filters/tdb-technology-secondary-filters.component';
import { CamelToTextPipe } from 'prosumer-shared/modules/tdb/pipes/camel-to-text/camel-to-text.pipe';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { LetDirective, PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';

import { TdbTechnologyFiltersComponent } from './tdb-technology-filters.component';

@NgModule({
  declarations: [
    TdbTechnologyFiltersComponent,
    TdbTechnologySecondaryFiltersComponent,
    CamelToTextPipe,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    LetDirective,
    PushPipe,
    SelectNewModule,
    MatButtonModule,
    NgxSliderModule,
    SharedModule,
  ],
  exports: [TdbTechnologyFiltersComponent],
})
export class TdbTechnologyFiltersModule {}
