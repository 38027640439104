import {
  mapKeysTDBTechDataToForm,
  mapToFormSelect,
} from 'prosumer-shared/modules/tdb/mappers';
import {
  TDBDataQueryNew,
  TDBDataStoreNew,
} from 'prosumer-shared/modules/tdb/stores/tdb-data-new';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  take,
  tap,
} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  ANY_VALUE,
  CATEGORIES_W_OPTION_ANY,
  PRIMARY_FILTERS,
  TDB_TECH_FILTER_SEQUENCE,
  TDB_TECH_TYPES_COLUMN_DEF,
  VALUES_TO_IGNORE,
} from './tdb-technology-filters.token';

@UntilDestroy()
@Component({
  selector: 'prosumer-tdb-technology-filters',
  templateUrl: './tdb-technology-filters.component.html',
  styleUrls: ['./tdb-technology-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TDBDataStoreNew, TDBDataQueryNew],
  encapsulation: ViewEncapsulation.None,
})
export class TdbTechnologyFiltersComponent implements OnInit {
  @Output() loadingState = new EventEmitter();
  @Output() dataSelected = new EventEmitter();
  @Input() technologyType: string | undefined;
  @Input() extraDataQueryParams: Record<string, string>;

  readonly primaryFiltersForm = this.initPrimaryFiltersForm();
  readonly categoryOptions$: Observable<Record<string, string>[]>;
  readonly subcategoryOptions$: Observable<Record<string, string>[]>;
  readonly subsubcategoryOptions$: Observable<Record<string, string>[]>;
  readonly isTdbApiResLoading$: Observable<boolean>;
  readonly secondaryFilters$: Observable<Record<string, string[] | number[]>>;
  readonly secondarySliders$: Observable<Record<string, string[] | number[]>>;
  readonly tdbData$: Observable<Record<string, unknown>[]>;
  columnsDef: Record<string, unknown>;
  filterSequence: string[];

  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly tdbStore: TDBDataStoreNew,
    private readonly tdbQuery: TDBDataQueryNew,
  ) {
    this.categoryOptions$ = this.selectFilterByKey('category');
    this.subcategoryOptions$ = this.selectFilterByKey('subcategory');
    this.subsubcategoryOptions$ = this.selectFilterByKey('subsubcategory');
    this.isTdbApiResLoading$ = this.tdbQuery.selectLoading().pipe(
      untilDestroyed(this),
      distinctUntilChanged(),
      tap((isLoading) => this.loadingState.emit(isLoading)),
    );
    this.secondaryFilters$ = this.tdbQuery
      .selectFilters()
      .pipe(map(this.pluckPrimaryFilters));
    this.secondarySliders$ = this.tdbQuery
      .selectSliders()
      .pipe(untilDestroyed(this));
    this.tdbData$ = this.tdbQuery.selectAll().pipe(untilDestroyed(this));
  }

  ngOnInit(): void {
    this.subscribeOnFormChanges();
    this.primaryFiltersForm.get('type').patchValue(this.technologyType);
    this.columnsDef = this.getColumnsDefinition();
    this.filterSequence = this.getFilterSequence();
  }

  checkForUnselectedControls(keys: string[]): boolean {
    return keys.some((key) =>
      VALUES_TO_IGNORE.includes(this.primaryFiltersForm.get(key).value),
    );
  }

  handleControlChange(keysListToReset: string[]) {
    this.resetControls(keysListToReset);
  }

  handleSecondarySubmit(secondaryFiltersFormData: Record<string, string>) {
    if (this.primaryFiltersForm.invalid) return;
    const criteria = this.cleanUpFormValues({
      ...this.primaryFiltersForm.value,
      ...secondaryFiltersFormData,
      ...this.extraDataQueryParams,
    });
    this.tdbStore.getTDBData(criteria).pipe(take(1)).subscribe();
  }

  onSelect(selected: unknown): void {
    const data = this.tdbQuery.getEntity(selected);
    const mappedData = mapKeysTDBTechDataToForm(data);
    this.dataSelected.emit(mappedData);
  }

  private subscribeOnFormChanges(): void {
    this.primaryFiltersForm.valueChanges
      .pipe(
        debounceTime(200),
        untilDestroyed(this),
        filter((_) => this.primaryFiltersForm.get('type').valid),
        map((form) => this.cleanUpFormValues(form)),
        mergeMap((form) =>
          this.tdbStore.getTDBFilters(form, 'getTechnologyFilters'),
        ),
      )
      .subscribe();
  }

  private cleanUpFormValues(form) {
    Object.keys(form).forEach((key) => {
      if (VALUES_TO_IGNORE.includes(form[key])) delete form[key];
    });
    return form;
  }

  private selectFilterByKey(key: string): Observable<Record<string, string>[]> {
    return this.tdbQuery.selectFilterValue(key).pipe(
      map((optionsList) => this.injectOptionAny(key, optionsList)),
      map((optionsList) => mapToFormSelect(optionsList)),
    );
  }

  private injectOptionAny(key, optionsList) {
    if (CATEGORIES_W_OPTION_ANY.includes(key)) optionsList.unshift(ANY_VALUE);
    return optionsList;
  }

  private initPrimaryFiltersForm() {
    return this._formBuilder.group({
      type: [undefined, Validators.required],
      category: [undefined, Validators.required],
      subcategory: ANY_VALUE,
      subsubcategory: ANY_VALUE,
    });
  }

  private resetControls(keys: string[]) {
    keys.forEach((key) => this.primaryFiltersForm.get(key).reset(ANY_VALUE));
  }

  private pluckPrimaryFilters(filtersData) {
    const secondaryFiltersData = { ...filtersData };
    PRIMARY_FILTERS.forEach((key) => delete secondaryFiltersData[key]);
    return secondaryFiltersData;
  }

  private getColumnsDefinition(): Record<string, unknown> {
    return TDB_TECH_TYPES_COLUMN_DEF[this.technologyType];
  }

  private getFilterSequence(): string[] {
    return TDB_TECH_FILTER_SEQUENCE[this.technologyType];
  }
}
