<div
  fxLayout="row wrap"
  fxLaoutAlign="start start"
  class="container-filter-inputs"
>
  <prosumer-select-new
    fxFlex="32.5%"
    *ngFor="let filter of filterDataOptionsMapped$ | ngrxPush"
    [options]="filter.values"
    [control]="secondaryFiltersForm.controls[filter.key]"
    [disabled]="isLoading"
    [label]="filter.key | camelToText | titlecase"
    [tooltip]="'tdb_filters.' + filter.key"
    [attr.data-testid]="'tdb-secondary-filters-select-' + filter.key"
  >
  </prosumer-select-new>
  <span class="break"></span>
  <div
    fxFlex="32.5%"
    class="slider-container"
    *ngFor="let slider of sliderDataOptionsMapped$ | ngrxPush"
  >
    <span
      class="slider-label"
      [attr.data-testid]="'tdb-secondary-filters-label-' + slider.key"
    >
      {{ slider.label }}
    </span>
    <ngx-slider
      [attr.data-testid]="'tdb-secondary-filters-slider-' + slider.key"
      [formControl]="secondaryFiltersForm.controls[slider.key]"
      [options]="getSliderOptions(slider.key)"
      [highValue]="slider.ceil"
      [value]="slider.floor"
    >
    </ngx-slider>
  </div>
</div>
<button
  mat-flat-button
  color="primary"
  [disabled]="isLoading"
  (click)="handleSubmitBtnClick()"
  data-testid="tdb-secondary-filters-submit-btn"
>
  {{ 'Scenario.labels.tdb.submitBtn' | translate }}
</button>
<!-- 
  +++[options]="subsubcategoryOptions$ | ngrxPush"
(selectionChange)="handleControlChange([])"
+++++[control]="primaryFiltersForm.controls.subsubcategory"
-----[placeholder]="'Scenario.placeholders.tdb.subsubcategory' | translate"
+++++[label]="'Scenario.labels.tdb.subsubcategory' | translate"
+++++[disabled]="loading || checkForUnselectedControls(['subcategory'])" -->
