import { Coerce } from 'prosumer-app/core/utils';
import { BehaviorSubject } from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'prosumer-unsaved-entity-banner',
  templateUrl: './unsaved-entity-banner.component.html',
  styleUrls: ['./unsaved-entity-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsavedEntityBannerComponent<
  T extends Record<string, unknown> = Record<string, unknown>,
> {
  unsavedEntityList = new BehaviorSubject<Record<string, unknown>[]>([]);

  @Input() set entityList(list: T[]) {
    this.unsavedEntityList.next(this.pluckUnsavedOptions(list));
  }
  @Output() addButtonClicked = new EventEmitter<T>();

  constructor() {}

  onClickAddButton(record: T): void {
    this.setEntityLoading(record);
    this.addButtonClicked.emit({ ...record });
  }

  private pluckUnsavedOptions(opts: T[]): T[] {
    return Coerce.toArray(opts).filter((opt) => opt.isUnsaved === true);
  }

  private setEntityLoading(record: T): void {
    const list = this.unsavedEntityList.getValue();
    const idx = list.findIndex((item) => item === record);
    list[idx] = { ...record, isSaving: true };
    this.unsavedEntityList.next(list);
  }
}
