import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TDBReferencePipe } from './tdb-reference.pipe';

@NgModule({
  declarations: [TDBReferencePipe],
  imports: [CommonModule],
  exports: [TDBReferencePipe],
})
export class TDBReferencePipeModule {}
